import axios from '@/plugins/axios'
import { BaseResponse } from '../base'
import {
  Exchange,
  ExchangeRefInstrument,
  ExchangeWalletPortfolioRes,
  ExchangeWalletPortfolioReq,
  ExchangeWalletBalance,
  Balances,
  ExchangeBalanceTotal,
  ExchangeBalanceTotalBalanceReq
} from './models'

const RESOURCES = Object.freeze({
  activeExchange: '/exchange/endpoints',
  exchangeRefInstrument: '/exchange/ref_instrument/view',
  exchangePortfolio: '/exchange/wallet/portfolio/all-exchanges',
  exchangeBalance: '/exchange/wallet/balance/all-exchanges',
  tradiasExchangeBalance: '/otc/balance',
  exchangeBalanceLineChart: '/exchange/wallet/balance/total/all-exchanges'
})

export default {
  getActiveExchanges() {
    return axios.get<BaseResponse<Exchange[] | null>>(RESOURCES.activeExchange)
  },

  getExchangeRefInstrument(params: Record<string, string>) {
    return axios.get<BaseResponse<ExchangeRefInstrument[] | null>>(RESOURCES.exchangeRefInstrument, { params })
  },

  getWalletPortfolio(params: ExchangeWalletPortfolioReq | undefined = undefined) {
    return axios.get<BaseResponse<ExchangeWalletPortfolioRes[] | null>>(RESOURCES.exchangePortfolio, { params })
  },

  getWalletBalance(params: ExchangeWalletPortfolioReq | undefined = undefined) {
    return axios.get<BaseResponse<Balances, ExchangeWalletBalance[] | null>>(RESOURCES.exchangeBalance, { params })
  },

  getTradiasWalletBalance(params: ExchangeWalletPortfolioReq | undefined = undefined) {
    return axios.get<BaseResponse<Balances, ExchangeWalletBalance[] | null>>(RESOURCES.tradiasExchangeBalance, {
      params
    })
  },

  getBalanceLineChart(params: ExchangeBalanceTotalBalanceReq) {
    return axios.get<BaseResponse<ExchangeBalanceTotal[]>>(RESOURCES.exchangeBalanceLineChart, { params })
  }
}
