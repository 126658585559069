export const CHART_COLORS: string[] = [
  '#00a59a',
  '#579ee0',
  '#6b7b8b',
  '#ee7d8b',
  'rgb(247, 158, 61)',
  'rgb(150, 40, 27)',
  '#1fb51f',
  '#0085ff',
  '#0d267a',
  '#ff1313',
  '#ff7620'
]
