














































import Vue from 'vue'
import { cloneDeep as _cloneDeep } from 'lodash'
import { ExchangeWalletBalance } from '@/services/exchange/models'
import { CHART_COLORS } from '../../constants'

export default Vue.extend({
  props: {
    market: {
      type: Array as () => ExchangeWalletBalance[],
      required: true
    }
  },

  data(): {
    tableDatas: any
    chartOption: Record<string, any>
    loading: boolean
    key: boolean
  } {
    return {
      chartOption: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          with: '100px',
          height: '100px',
          spacing: [0, 0, 0, 0],
          backgroundColor: '#1f2324'
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: _cloneDeep(CHART_COLORS),
            dataLabels: {
              enabled: false
            }
          }
        },
        series: [
          {
            name: 'Share',
            data: [
              // { name: 'Chrome', y: 61.41 },
              // { name: 'Internet Explorer', y: 11.84 },
              // { name: 'Firefox', y: 10.85 }
            ]
          }
        ],
        credits: {
          enabled: false
        }
      },
      tableDatas: [],
      loading: false,
      key: false
    }
  },

  methods: {
    calculateChart() {
      const exchangeBalance = _cloneDeep(this.market)
      const chartOption = exchangeBalance.map((item) => ({
        name: item.ccy,
        y: item.balance_usd
      }))

      this.chartOption.series[0].data = chartOption
      this.key = !this.key
    }
  },

  mounted() {
    this.calculateChart()
  },

  watch: {
    market: {
      handler() {
        this.calculateChart()
      },
      deep: true
    }
  }
})
