



















import Vue from 'vue'
import ExchangeService from '@/services/exchange'
import { ExchangeWalletBalance } from '@/services/exchange/models'
import { cloneDeep as _cloneDeep, groupBy as _groupBy } from 'lodash'
import Exchange from '../Exchange/index.vue'

export default Vue.extend({
  data(): {
    markets: ExchangeWalletBalance[] | null | undefined
    loading: boolean
  } {
    return {
      markets: [],
      loading: false
    }
  },

  components: {
    Exchange
  },

  methods: {
    async getActiveExchanges() {
      this.loading = true
      this.markets = []

      try {
        const { data: response } = await ExchangeService.getWalletBalance()
        if (response.success && response.custom && response.custom.data) {
          const markets = _groupBy(_cloneDeep(response.custom?.data), 'exchange')
          for (const exchange in markets) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.markets.push(markets[exchange])
          }
        }
      } catch (_) {
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    this.getActiveExchanges()
  }
})
